import React from "react";
import "./404.css"; // Import the CSS stylesheet for this component
const NotFoundPage = (
) => {
  return (
    <div className="not-found-container">
      <div className="not-found">
        <h1 className="not-found-title">Oops!</h1>
        <h2 className="not-found-subtitle">We couldn't find this page</h2>
        <p className="not-found-description">
          It seems like the page you're looking for doesn't exist. You can try
          searching for what you're looking for or navigate back to the homepage.
        </p>
        <a
          className="not-found-button"
          href="https://www.itforsec.com" // Replace with the desired URL
          // target="_blank" // Optional: Open the link in a new tab
          rel="noreferrer" // Optional: Avoid sending the referrer
        >
          Back to Homepage
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
