
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Paper, Menu, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./404.js"; // Import the 404 component



// import FAQ from './FAQ';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f44336',
    },
  },
});




function App() {
  
  const [isDarkMode, setIsDarkMode] = useState(true); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setAnchorEl(null);
  };

  //const [phoneNumber,] = useState('+995 599-400-135');
  
  //const handlePhoneCall = () => {
    //window.open(`tel:${phoneNumber}`);
  //};
  const handleWhatsApp = () => {
    const phoneNumber = '+995599400135'; // Make sure the number is in international format
    window.open(`https://wa.me/${phoneNumber}`);
  };
  




  const content =  (
    <ThemeProvider theme={theme} language={selectedLanguage}>
            <AppBar position="static" color={isDarkMode ? 'default' : 'primary'}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="div">
              IT For Sec
            </Typography>
            <div>
              <Button color={isDarkMode ? 'primary' : 'inherit'} onClick={toggleTheme}>
                {isDarkMode ? 'Light' : 'Dark'}
              </Button>
              <Button color="inherit" onClick={handleLanguageClick}>
                {selectedLanguage === 'en' ? 'English' : 'Georgian'}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleLanguageClose}
                keepMounted
              >
                <MenuItem onClick={() => handleLanguageSelect('en')}>Georgian</MenuItem>
                <MenuItem onClick={() => handleLanguageSelect('ge')}>English</MenuItem>
              </Menu>
          


            </div>
          </Toolbar>
        </AppBar>
      <Container sx={{ my: 4 }}>
        <Grid container spacing={4} justifyContent="center">
          {/* Header Section */}
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 4, backgroundColor: isDarkMode ? '#424242' : '#fff', color: isDarkMode ? '#fff' : '#424242', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h4" component="h4" sx={{ mb: 4, textAlign: 'center' }}>
                {selectedLanguage === 'ge' ? 'Welcome to Our IT Support Service' : ' IT მხარდაჭერის სერვისი'}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', mb: 4 }}>
                {selectedLanguage === 'ge' ? 'We provide comprehensive IT solutions tailored to your business needs. Our team of experts is dedicated to ensuring your IT systems run seamlessly, allowing you to focus on your core business objectives.' : 'ჩვენ გთავაზობთ სრულყოფილ IT სოლუშიონს, რომლებიც მორგებულია თქვენი ბიზნესის საჭიროებებზე.'}
              </Typography>
             {/* <Button variant="contained" color="secondary" onClick={handlePhoneCall}>
                Call
              </Button>
              */}
              <Button variant="contained" color="secondary" onClick={handleWhatsApp}>
                Contact Us
              </Button>



            </Paper>
          </Grid>

        


                {/* Services Section */}
                <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '80%', backgroundColor: isDarkMode ? '#424242' : '#fff', color: isDarkMode ? '#fff' : '#424242', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/* Services Section Content */}
            <Typography variant="h5" component="h5" sx={{ mb: 0, textAlign: 'center' }}>
              {selectedLanguage === 'ge' ? 'Services' : 'სერვისები'}
            </Typography>
                    <ul>
                      <li>{selectedLanguage === 'ge' ? 'Operating System and Software Installation' : 'ოპერაციული სისტემებისა და პროგრამული პროდუქტების ინსტალაცია'}</li>
                      <li>{selectedLanguage === 'ge' ? 'Scripting for Automation and Task Simplification' : 'სპეციფიკური სკრიპტების წერა სისტემის აუტომატიზაციისა და გამარტივებისთვის'}</li>
                      <li>{selectedLanguage === 'ge' ? 'Custom Software Development' : 'პროგრამული პროდუქტის შექმნა და მორგება'}</li>
                      <li>{selectedLanguage === 'ge' ? 'Desktop Task Simplification' : 'დესკტოპთან მუშაობის გამარტივება'}</li>
                      <li>{selectedLanguage === 'ge' ? 'Network Security' : 'ქსელის უსაფრთხოება'}</li>
                      <li>{selectedLanguage === 'ge' ? 'Web Development' : 'ვებ-საიტების დეველოპმენტი'}</li>
                      <li>{selectedLanguage === 'ge' ? 'Web3 Solutions' : 'Web3-ზე დაფუძნებული პროექტები'}</li>

                    </ul>
                  </Paper>
                </Grid>

                {/* About Us Section */}
                <Grid item xs={12} md={6}>
          <Paper sx={{ p: 4, height: '80%', backgroundColor: isDarkMode ? '#424242' : '#fff', color: isDarkMode ? '#fff' : '#424242', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/* About Us Section Content */}
            <Typography variant="h5" component="h5" sx={{ mb: 4, textAlign: 'center' }}>
              {selectedLanguage === 'ge' ? 'About Us' : 'ჩვენს შესახებ'}
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left' }}>
              {selectedLanguage === 'ge' ? 'At IT For Sec, we are a leading IT support service provider dedicated to delivering exceptional solutions to businesses of all sizes. With our team of highly skilled professionals and extensive industry experience, we are committed to helping our clients optimize their IT infrastructure and maximize productivity.' : 'ITFORSEC-ში ჩვენ ვართ წამყვანი IT მხარდაჭერის მიმწოდებლები, რომელიც უზრუნველყოფს განსაკუთრებული გადაწყვეტილებების მიწოდებას ყველა ზომის ბიზნესისთვის. ITFORSEC-ი გახლავთ: მაღალკვალიფიციური პროფესიონალების გუნდით და ინდუსტრიის ფართო გამოცდილებით. ჩვენ მზად ვართ დავეხმაროთ ჩვენს კლიენტებს IT ინფრასტრუქტურის ოპტიმიზაციაში და მაქსიმალური პროდუქტიულობის გაზრდაში.'}
            </Typography>
                  </Paper>
                </Grid>


          {/* More info */}
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 4, backgroundColor: isDarkMode ? '#424242' : '#fff', color: isDarkMode ? '#fff' : '#424242', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h4" component="h4" sx={{ mb: 4, textAlign: 'center' }}>
                {selectedLanguage === 'ge' ? 'More details:' : 'მეტი ინფორმაცია:'}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'left', mb: 4 }}>
              
              <ul>
                <li>{selectedLanguage === 'ge' ? 'IT Infrastructure Management: We offer comprehensive IT infrastructure management services, ensuring that your systems are running efficiently and securely. From network setup and maintenance to server management and data backup, we handle it all' :
                 'IT ინფრასტრუქტურის მენეჯმენტი: ჩვენ გთავაზობთ IT ინფრასტრუქტურის მართვის სრულ სერვისებს, რაც უზრუნველყოფს თქვენი სისტემების ეფექტურად და უსაფრთხოდ მუშაობას. ქსელის დაყენებიდან და შენარჩუნებიდან დაწყებული სერვერის მენეჯმენტამდე და მონაცემთა სარეზერვო შენახვამდე, ჩვენ ყველაფერს ვუზრუნველყოფთ.'}</li>
                <li>{selectedLanguage === 'ge' ? 'Software Solutions: Whether you need customized software development, software installation, or integration with existing systems, we have the expertise to deliver high-quality solutions tailored to your unique business requirements.' : 
                'პროგრამული გადაწყვეტილებები: გჭირდებათ თუ არა მორგებული პროგრამული უზრუნველყოფის შემუშავება? პროგრამული უზრუნველყოფის ინსტალაცია ან ინტეგრაცია არსებულ სისტემებთან? ჩვენ გვაქვს გამოცდილება, რომ მივიღოთ მაღალი ხარისხის გადაწყვეტილებები, რომლებიც მორგებული იქნება თქვენს უნიკალურ ბიზნეს მოთხოვნებზე.'}</li>
                <li>{selectedLanguage === 'ge' ? 'Network Security: We understand the importance of protecting your valuable data and ensuring network security. Our team implements robust security measures to safeguard your systems against potential threats, ensuring the integrity and confidentiality of your information.' : 
                ' ქსელის უსაფრთხოება: ჩვენ გვესმის თქვენი ღირებული მონაცემების დაცვისა და ქსელის უსაფრთხოების უზრუნველყოფის მნიშვნელობა. ჩვენი გუნდი ახორციელებს უსაფრთხოების მკაცრ ზომებს თქვენი სისტემების პოტენციური საფრთხეებისგან დასაცავად, თქვენი ინფორმაციის მთლიანობისა და კონფიდენციალურობის უზრუნველსაყოფად.'}</li>
                <li>{selectedLanguage === 'ge' ? 'Automation and Efficiency: We specialize in automating repetitive tasks, streamlining workflows, and improving overall efficiency. By leveraging cutting-edge technologies and scripting solutions, we help businesses save time and reduce manual errors.' : 
                'ავტომატიზაცია და ეფექტურობა: ჩვენ სპეციალიზირებული ვართ განმეორებადი ამოცანების ავტომატიზაციაში, სამუშაო ნაკადების გამარტივებაში და საერთო ეფექტურობის გაუმჯობესებაში. უახლესი ტექნოლოგიებისა და სკრიპტირების გადაწყვეტილებების გამოყენებით, ჩვენ ვეხმარებით ბიზნესს დაზოგონ დრო და შეამცირონ საქმეში შეცდომები.'}</li>
                <li>{selectedLanguage === 'ge' ? 'Web Development: Our skilled web developers create visually appealing and user-friendly websites that effectively represent your brand and engage your target audience. We utilize modern web technologies to deliver responsive and optimized websites.' : 
                'ვებ-დეველოპმენტი: ჩვენი გამოცდილი ვებ დეველოპერები ქმნიან ვიზუალურად მიმზიდველ და მოსახერხებელი ვებსაიტებს, რომლებიც ეფექტურად წარმოადგენენ თქვენს ბრენდს და კონცენტრირდებიან თქვენს მიერ გამიზნულ აუდიტორიაზე. ჩვენ ვიყენებთ თანამედროვე ვებ ტექნოლოგიებს ოპტიმიზებული ვებსაიტების მისაწოდებლად. '}</li>
                <li>{selectedLanguage === 'ge' ? 'Consulting and Support: As your trusted IT partner, we provide expert consulting services to help you make informed decisions about your IT strategy. We also offer ongoing support to ensure that your systems operate smoothly and are always up to date.' :
                 'კონსულტაცია და მხარდაჭერა: როგორც თქვენი სანდო IT პარტნიორი, ჩვენ გთავაზობთ ექსპერტთა საკონსულტაციო მომსახურებას, რათა დაგეხმაროთ მიიღოთ ინფორმირებული გადაწყვეტილებები თქვენი IT სტრატეგიის შესახებ. ჩვენ ასევე გთავაზობთ მუდმივ მხარდაჭერას იმის უზრუნველსაყოფად, რომ თქვენი სისტემები მუშაობენ შეუფერხებლად და ყოველთვის განახლებულ რეჟიმში.'}</li>
                
              </ul>
              
              </Typography>
              
            </Paper>
          </Grid>

         
       



          {/* Contact Us Section */}
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 4, backgroundColor: isDarkMode ? '#424242' : '#fff', color: isDarkMode ? '#fff' : '#424242', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h5" component="h5" sx={{ mb: 3, textAlign: 'center' }}>
                {selectedLanguage === 'ge' ? 'Contact Us' : 'დაგვიკავშირდით'}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {selectedLanguage === 'ge' ? 'Email: info@itforsec.com' : 'ელ.ფოსტა: info@itforsec.com'}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {selectedLanguage === 'ge' ? 'Phone: +995 599-400-135' : 'ტელეფონი: +995 599-400-135'}
              </Typography>
            </Paper>
          </Grid>
          
          

          {/* Footer Section */}
          <Grid item xs={12} md={12}>
            <Paper sx={{ p: 4, backgroundColor: isDarkMode ? '#424242' : '#fff', color: isDarkMode ? '#fff' : '#424242', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {selectedLanguage === 'ge' ? '© 2023 IT For Sec. All rights reserved.' : '© 2023 IT For Sec. ყველა უფლება დაცულია.'}
              </Typography>
            </Paper>
          </Grid>
          </Grid>
          </Container>
          </ThemeProvider>

    
    

  );

    
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={content} />
        <Route exact path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  )

  

}

export default App;
